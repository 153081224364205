import React, { useEffect, useState, useRef } from "react";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { Link } from "react-router-dom";
import { MasjidColumns } from "./MasjidColumns";
import { getMasjids } from "../../../Redux/Actions/MasjidActions/FetchingMasjids";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";
import { FcPlus } from "react-icons/fc";
import "./MasjidTablle.scss";
import TextField from "@mui/material/TextField";
import { useDebounce } from "use-debounce";
import { AiOutlineCloseCircle } from "react-icons/ai";
import { useStateContext } from "../../../contexts/StateContextProvider";
import { ChangeSnackbar } from "../../../Redux/Actions/SnackbarActions/SnackbarActions";
import { useNavigate } from "react-router-dom";
import {
  MasjidDataType,
  addNewMasjid,
} from "../../../Redux/Actions/MasjidActions/AddingMasjids";
import { handleSnackbar } from "../../../helpers/SnackbarHelper/SnackbarHelper";
import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css";
import "react-lazy-load-image-component/src/effects/blur.css";
import { useAppSelector, useAppThunkDispatch } from "../../../Redux/hooks";
import { AdminInterFace } from "../../../Components/FeedComponents/Feed/Feed";
import SocialInput from "../../../Pages/Shared/SocialInput/SocialInput";
import { mailChecker, phoneChecker } from "../../../helpers/HelperFunction";
import toast from "react-hot-toast";
import EmailChip from "../../../Pages/Shared/EmailChip/EmailChip";
import { ReceivedEmailObject } from "../../../../Types";
import { ApolloError, useMutation, useQuery } from "@apollo/client";
import { GET_MASJIDS } from "../../../graphql/queries";
import { CREATE_MASJID } from "../../../graphql/mutations";
import { CircularProgress } from "@mui/material";

const MasjidTable = () => {
  const [text, setText] = useState("");
  const { setSearchTerm } = useStateContext()!;
  const { results } = useStateContext()!;
  const [debouncedValue] = useDebounce(text, 1600);
  const { getResults, searchTerm } = useStateContext()!;
  const [selectedValue, setSelectedValue] = useState<any>(null);
  let MasjidNameRef = useRef<HTMLInputElement | null>(null);
  let masjidNameInputErrorRef = useRef<HTMLInputElement | null>(null);
  const CountryRef = useRef<string | null>(null);
  let MasjidDescRef = useRef<HTMLInputElement | null>(null);
  let MasjidAddressRef = useRef<HTMLInputElement | null>(null);
  let MasjidLatitudeRef = useRef<HTMLInputElement | null>(null);
  let MasjidLongitudeRef = useRef<HTMLInputElement | null>(null);
  let MasjidContactRef = useRef<any>(null);
  let MasjidFacebookLinkRef = useRef<HTMLInputElement | null>(null);
  let MasjidWebsiteLinkRef = useRef<HTMLInputElement | null>(null);
  let whatsAppNumRef = useRef<HTMLInputElement | null>(null);
  let emailRef = useRef<HTMLInputElement | null>(null);
  const [MasjidNames, setMasjidNames] = useState([]);
  const dispatch = useAppThunkDispatch();
  const [AddModalOpen, setAddModalOpen] = useState(false);
  const [country, setCountry] = React.useState("");
  const [MasjidAddressError, setMasjidAddressError] = useState(false);
  const [addressErrorMgs, setAddressErrorMgs] = useState("Manditory field");
  const [MasjidNameError, setMasjidNameError] = useState(false);
  const [MasjidBracketError, setMasjidBracketError] = useState(false);
  const [MasjidDescError, setMasjidDescError] = useState(false);
  const [MasjidLatitudeError, setMasjidLatitudeError] = useState(false);
  const [MasjidLongitudeError, setMasjidLongitudeError] = useState(false);
  const [MasjidFacebookError, setMasjidFacebookError] = useState(false);
  const [MasjidWebsiteLinkError, setMasjidWebsiteLinkError] = useState(false);
  const [whatsAppError, setWhatsAppError] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const [receivedEmails, setReceivedEmails] = useState<ReceivedEmailObject[]>(
    []
  );
  const [MasjidPhoneError, setMasjidPhoneError] = useState(false);
  const [MasjidDuplicationDropDown, setMasjidDuplicationDropDown] =
    useState(false);
  const [DropDownOpen, setDropDownOpen] = useState(false);
  const [LatPaste, setLatPaste] = useState(0);
  const [LonPaste, setLonPaste] = useState(0);
  let navigate = useNavigate();
  const adminString = localStorage.getItem("admin");
  const admin: AdminInterFace | null = adminString
    ? JSON.parse(adminString)
    : null;

  // const masjids = useAppSelector((state) => state.masjids);
  const [masjids, setMasjids] = useState([]);
  const { loading, error, data } = useQuery(GET_MASJIDS, {
    variables: { limit: 0, pages: 0 },
  });
  useEffect(() => {
    if (data && data.getMasjids) {
      setMasjids(data.getMasjids); // Set masjids state with the data
    } else if (error) {
      toast.error("Error fetching masjids");
    }
  }, [data, error]);
  const [
    createMasjid,
    { data: createData, loading: createLoading, error: creatError },
  ] = useMutation(CREATE_MASJID);

  const handleCreateMasjid = async (masjidData: any) => {
    try {
      const response = await createMasjid({
        variables: {
          input: masjidData,
        },
      });

      toast.success("Masjid created successfully");
      if (response?.data?.createMasjid?._id) {
        setTimeout(() => {
          navigate("/masjid/" + response.data.createMasjid._id);
        }, 1000);
        setAddModalOpen(false);
        setSelectedValue(null);
      }
    } catch (err) {
      toast.success("Error creating masjid:", err);
      console.error("Error creating masjid:", err);
    }
  };
  useEffect(() => {
    if (admin?.role === "superadmin" || admin?.role === "admin") {
      dispatch(getMasjids("createdAt", "asc"));
    }
  }, []);

  useEffect(() => {
    if (text.length > 28) {
      DropDownOpen && setDropDownOpen(false);
    }
    if (debouncedValue) setSearchTerm(debouncedValue);
  }, [debouncedValue]);

  useEffect(() => {
    if (searchTerm === "") {
      setSelectedValue(null);
    }
    if (searchTerm !== "") {
      if (searchTerm.length < 28) {
        getResults(searchTerm);

        if (
          Array.isArray(results) &&
          results.length > 0 &&
          !results.some((result) => result.error)
        ) {
          setDropDownOpen(true);
        } else {
          setDropDownOpen(false);
        }
      }
    }
  }, [searchTerm]);

  const handleModalUpdateClose = () => {
    setAddModalOpen(false);
    setMasjidBracketError(false);
    setMasjidAddressError(false);
    setMasjidNameError(false);
    setMasjidDescError(false);
    setMasjidLatitudeError(false);
    setMasjidLongitudeError(false);
    setMasjidPhoneError(false);
    setMasjidFacebookError(false);
    setMasjidWebsiteLinkError(false);
    setCountry("");
    setText("");
  };

  const handleAddMasjid = (data: MasjidDataType) => {
    const response = dispatch(addNewMasjid(data, navigate));
    response.then(function (result) {
      if (result.success) {
        setTimeout(() => {
          navigate("/masjid/" + result.data._id);
        }, 1000);
        setAddModalOpen(false);
        setSelectedValue(null);
      } else {
        handleSnackbar(
          true,
          "error",
          `Adding Masjid Failed:${result.message}`,
          dispatch
        );
      }
    });
  };

  const handleNewMasjid = () => {
    let coordinates = [
      parseFloat(MasjidLatitudeRef.current?.value ?? "0"),
      parseFloat(MasjidLongitudeRef.current?.value ?? "0"),
    ];

    if (text.length <= 0) {
      setMasjidAddressError(true);
    } else if (MasjidAddressRef.current?.value) {
      setMasjidAddressError(false);
    }

    if (!MasjidNameRef.current?.value) {
      setMasjidNameError(true);
    } else if (MasjidNameRef.current?.value) {
      setMasjidNameError(false);
    }

    if (!MasjidDescRef.current?.value) {
      setMasjidDescError(true);
    } else if (MasjidDescRef.current?.value) {
      setMasjidDescError(false);
    }

    if (!MasjidLatitudeRef.current?.value) {
      setMasjidLatitudeError(true);
    } else if (MasjidLatitudeRef.current?.value) {
      setMasjidLatitudeError(false);
    }

    if (!MasjidLongitudeRef.current?.value) {
      setMasjidLongitudeError(true);
    } else if (MasjidLongitudeRef.current?.value) {
      setMasjidLongitudeError(false);
    }

    if (MasjidContactRef.current?.value && !country) {
      toast.error("Place Country Code Before the Number.");
      return;
    }
    if (!country) {
      toast.error("Ensure that you have selected a country before proceeding ");
      return;
    }
    // if (!MasjidContactRef.current?.value) {
    //   setMasjidPhoneError(true);
    // }

    const fbLink = MasjidFacebookLinkRef.current?.value;
    const webLink = MasjidWebsiteLinkRef.current?.value;
    const whatsAPP = whatsAppNumRef.current?.value;
    const emailVal = emailRef.current?.value;

    if (emailVal && !mailChecker(emailVal)) return;
    let externalLink = [
      { name: "Facebook", url: fbLink },
      { name: "Website", url: webLink },
      { name: "Whatsapp", url: whatsAPP },
      { name: "Email", url: emailVal },
      ...receivedEmails,
    ];
    externalLink = externalLink.filter((link) => link.url);

    let updatedData: MasjidDataType = {
      masjidName: MasjidNameRef.current?.value ?? "",
      description: MasjidDescRef.current?.value ?? "",
      address: text + " " + country,
      location: {
        type: "Point",
        coordinates: [coordinates[1], coordinates[0]],
      },
      contact: MasjidContactRef.current?.value.replace(/\s+/g, "") ?? "",
    };
    if (externalLink.length > 0) {
      updatedData = { ...updatedData, externalLinks: externalLink };
    }

    if (
      coordinates[0] &&
      coordinates[1] &&
      MasjidDescRef.current?.value &&
      MasjidNameRef.current?.value &&
      text &&
      !MasjidBracketError
      // MasjidContactRef.current?.value
    ) {
      handleCreateMasjid(updatedData);
      // handleAddMasjid(updatedData);
    } else {
      handleSnackbar(
        true,
        "warning",
        "Please enter correct details before trying again",
        dispatch
      );
    }
  };

  const handleAddMasjidModal = () => {
    setAddModalOpen(true);
  };

  function validate(field: any, regex: any) {
    if (regex.test(field.value)) {
      field.className = "correctInput";
      if (MasjidBracketError) {
        setMasjidBracketError(false);
      }
    } else {
      if (!MasjidBracketError) {
        setMasjidBracketError(true);
      }
      field.className = "errorInput";
    }
  }

  const checkBrackets = (field: any) => {
    const stack = [];
    const bracketLookup: { [key: string]: string } = {
      "{": "}",
      "(": ")",
      "[": "]",
    };
    for (const key of field.value) {
      if (Object.keys(bracketLookup).includes(key)) {
        // matches open brackets
        stack.push(key);
      } else if (Object.values(bracketLookup).includes(key)) {
        //matches closed brackets
        const lastBracket = stack.pop();
        if (bracketLookup[lastBracket] !== key) {
          return false;
        }
      }
    }
    if (stack.length > 0) {
      if (!MasjidBracketError) {
        setMasjidBracketError(true);
      }
      field.className = "errorInput";
    } else if (MasjidBracketError) {
      setMasjidBracketError(false);
    }
    return stack.length === 0;
  };

  const checkDuplications = (name: any) => {
    if (name.value?.length >= 5 && name.value?.length <= 35) {
      let masjidNames: any = [];
      let Names = masjids.filter((item) => {
        let masjidName = item.masjidName.toLowerCase().trim();
        return masjidName.includes(name.value.toLowerCase().trim());
      });

      if (Names.length > 0) {
        !MasjidDuplicationDropDown && setMasjidDuplicationDropDown(true);
        Names.map((item) => masjidNames.push(item.masjidName));
        setMasjidNames(masjidNames);
      } else {
        setMasjidNames([]);
        setMasjidDuplicationDropDown(false);
      }
    } else {
      if (name.value.length < 4) {
        MasjidDuplicationDropDown && setMasjidDuplicationDropDown(false);
      }
    }
  };

  const patterns: Record<string, RegExp> = {
    masjidName: /^[a-zA-Z ,()-]+$/,
  };

  const handleInputCheckChange = (e: any) => {
    validate(e.target, patterns[e.target.attributes.name.value]);
    checkBrackets(e.target);
    checkDuplications(e.target);
  };

  const handleCountryChange = (country: string) => {
    CountryRef.current = country;
    setCountry(country);
  };

  const handleDropDown = () => {
    setDropDownOpen(false);
  };

  const onChoose = (searchTerm: any) => {
    setText(searchTerm.display_address);
    setSelectedValue(searchTerm);
    setDropDownOpen(false);
  };

  const handleTextChange = (event: any) => {
    const inputTex = event.target.value;
    setText(inputTex);
    const specialCharacterRegex = /[^,a-zA-Z0-9\s]/;
    if (specialCharacterRegex.test(inputTex)) {
      setMasjidAddressError(true);
      setAddressErrorMgs("Address contains special characters");
    } else {
      setMasjidAddressError(false);
      setAddressErrorMgs("mandatory  field");
    }
    if (event.target?.value.length <= 0) {
      setDropDownOpen(false);
    }
  };

  const handleLatPaste = (e: any) => {
    let pasted = e.clipboardData.getData("Text");
    let string1 = `${pasted}`;
    if (string1.includes(",")) {
      let locOfDivide = string1.indexOf(",");
      let lat = string1.substr(0, locOfDivide);
      let lon = string1.substr(locOfDivide + 2);
      setLatPaste(parseFloat(lat));
      MasjidLatitudeRef.current!.value = lat;
      setLonPaste(parseFloat(lon));
      MasjidLongitudeRef.current!.value = lon;
    } else {
      setLatPaste(parseFloat(string1));
      MasjidLatitudeRef.current!.value = string1;
    }
  };

  const handleLongPaste = (e: any) => {
    let pasted = e.clipboardData.getData("Text");
    let string1 = `${pasted}`;
    if (string1.includes(",")) {
      let locOfDivide = string1.indexOf(",");
      let lat = string1.substr(0, locOfDivide);
      let lon = string1.substr(locOfDivide + 2);
      setLatPaste(parseFloat(lat));
      MasjidLatitudeRef.current!.value = "" + parseFloat(lat);
      setLonPaste(parseFloat(lon));
      MasjidLongitudeRef.current!.value = "" + parseFloat(lon);
    } else {
      setLonPaste(parseFloat(string1));
      MasjidLongitudeRef.current!.value = "" + parseFloat(string1);
    }
  };

  function handleEmptyPaste(e: any) {
    if (text.length < 1) {
      const snackbarFailureDetails = {
        snackbarOpen: true,
        snackbarType: "warning",
        snackbarMessage:
          "Please Add the Address to paste the longitude and latitude",
      };
      dispatch(ChangeSnackbar(snackbarFailureDetails));
    }
  }
  const handlePhoneChange = (num: string) => {
    // if (!country) toast.error("you Have to Select Number First");
  };

  return (
    <div className="StaleMasjidTableContainer">
      <Dialog open={AddModalOpen}>
        <DialogTitle>Add New Masjid</DialogTitle>
        <DialogContent>
          <input
            id="outlined-required"
            // fullWidth
            // margin="dense"
            name="masjidName"
            className="defaultNameInput"
            placeholder="Enter the masjid Name"
            // sx={{ marginBottom: "20px", height: "30px", width: "80%" }}
            type="text"
            min={6}
            autoComplete="Off"
            required
            pattern="/^[a-zA-Z ,()-]+$/"
            onChange={handleInputCheckChange}
            ref={MasjidNameRef}
            defaultValue={
              MasjidNameRef.current?.value && MasjidNameRef.current?.value
            }
          />
          {MasjidBracketError || MasjidNameError ? (
            <p className="helperTextInput" ref={masjidNameInputErrorRef}>
              Masjid Name must have letters and circular brackets only and
              contain at least 6 characters{" "}
            </p>
          ) : (
            <div className="BottomForName"> </div>
          )}
          {MasjidDuplicationDropDown && (
            <div className="MasjidNamesDropdown">
              <div className="MasjidNamesDropdownContent">
                {MasjidDuplicationDropDown &&
                  MasjidNames.slice(0, 5).map((item) => (
                    <Link
                      to={"/masjid/" + item}
                      className="MasjidNamesDropdownElement"
                    >
                      {item}
                    </Link>
                  ))}
              </div>
            </div>
          )}
          <br />
          <div style={{ display: "flex", flexDirection: "row" }}>
            <TextField
              key="address"
              value={text}
              error={MasjidAddressError}
              helperText={MasjidAddressError ? addressErrorMgs : ""}
              autoComplete="Off"
              type="text"
              placeholder="🔎 Enter Masjid Address or Name"
              onChange={(e) => {
                handleTextChange(e);
              }}
              inputProps={{ style: { fontSize: 13 } }}
              fullWidth
            />

            {DropDownOpen && (
              <AiOutlineCloseCircle
                className="CloseIcon"
                onClick={handleDropDown}
              />
            )}
          </div>
          {!DropDownOpen && <div style={{ marginBottom: "1.2rem" }}></div>}
          {DropDownOpen &&
            results.length > 0 &&
            !results.some((result) => result.error) && (
              <div className="MasjidNamesDropdown">
                <div className="MasjidNamesDropdownContent">
                  {results.length > 0 &&
                    !results.some((result) => result.error) &&
                    results.slice(0, 15).map((item) => (
                      <div
                        onClick={() => onChoose(item)}
                        className="dropdownElement"
                      >
                        {item.display_name}
                      </div>
                    ))}
                </div>
              </div>
            )}
          {text.length > 10 && !selectedValue ? (
            <TextField
              required
              id="outlined-required"
              autoComplete="off"
              error={MasjidLatitudeError}
              helperText={MasjidLatitudeError ? "Manditory field" : ""}
              sx={{ marginBottom: 3, marginTop: 2 }}
              placeholder="Latitude"
              defaultValue={LatPaste && LatPaste}
              onPaste={(e: any) => {
                e.target.blur();
                handleLatPaste(e);
              }}
              inputRef={MasjidLatitudeRef}
              type="number"
            />
          ) : (
            <TextField
              type="number"
              required
              error={MasjidLatitudeError}
              helperText={MasjidLatitudeError ? "Manditory field" : ""}
              id="outlined-required"
              autoComplete="off"
              inputRef={MasjidLatitudeRef}
              onChange={(e) => {
                if (text.length < 10) {
                  setSelectedValue(null);
                }
              }}
              sx={{ marginBottom: 3, marginTop: 2 }}
              onPaste={(e) => {
                e.preventDefault();
                handleEmptyPaste(e);
                return false;
              }}
              placeholder="Latitude"
              value={selectedValue && selectedValue.lat}
            />
          )}
          {text.length > 18 && !selectedValue ? (
            <TextField
              required
              id="outlined-required"
              autoComplete="Off"
              error={MasjidLongitudeError}
              helperText={MasjidLongitudeError ? "Manditory field" : ""}
              sx={{ marginBottom: 3, marginLeft: 5, marginTop: 2 }}
              placeholder="Longitude"
              defaultValue={LonPaste && LonPaste}
              onPaste={(e: any) => {
                e.target.blur();
                handleLongPaste(e);
              }}
              inputRef={MasjidLongitudeRef}
              type="number"
            />
          ) : (
            <TextField
              required
              type="number"
              autoComplete="Off"
              error={MasjidLongitudeError}
              helperText={MasjidLongitudeError ? "Manditory field" : ""}
              sx={{ marginBottom: 3, marginLeft: 7, marginTop: 2 }}
              placeholder="Longitude"
              id="outlined-required"
              inputRef={MasjidLongitudeRef}
              onPaste={(e) => {
                e.preventDefault();
                handleEmptyPaste(e);
                return false;
              }}
              value={selectedValue && selectedValue?.lon}
            />
          )}

          <div className="phoneInputContainer">
            <PhoneInput
              error={MasjidPhoneError}
              placeholder="Enter phone number"
              onCountryChange={(e: any) => handleCountryChange(e)}
              ref={MasjidContactRef}
              style={{ width: "30rem", height: "100%", outline: "none" }}
              onChange={handlePhoneChange}
            />
          </div>
          {MasjidPhoneError && (
            <p style={{ color: "red" }}>Please Enter the Contact Number</p>
          )}

          <TextField
            id="outlined-required"
            variant="outlined"
            error={MasjidDescError}
            helperText={MasjidDescError ? "Manditory field" : ""}
            fullWidth
            multiline
            rows={4}
            inputRef={MasjidDescRef}
            type="text"
            autoComplete="Off"
            sx={{ marginTop: 3, height: "50%" }}
            placeholder="Enter the Description of the masjid"
          />
          <SocialInput
            isError={MasjidFacebookError}
            inputRf={MasjidFacebookLinkRef}
            placeholderTx={"Facebook Link"}
            height={"20%"}
          />
          <SocialInput
            isError={MasjidWebsiteLinkError}
            inputRf={MasjidWebsiteLinkRef}
            placeholderTx={"Website Link"}
          />
          <SocialInput
            isError={whatsAppError}
            inputRf={whatsAppNumRef}
            placeholderTx={"What's App  Group Name"}
          />
          <SocialInput
            isError={emailError}
            inputRf={emailRef}
            placeholderTx={"Official Email Address"}
          />
          <EmailChip emails={receivedEmails} setEmails={setReceivedEmails} />
        </DialogContent>
        <DialogActions style={{ justifyContent: "space-around" }}>
          <Button onClick={handleModalUpdateClose} style={{ color: "grey" }}>
            Cancel
          </Button>
          <Button
            data-testid="add-button"
            onClick={handleNewMasjid}
            disabled={createLoading} // Disable the button while loading
            startIcon={createLoading ? <CircularProgress size={20} /> : null} // Add a spinner
          >
            {createLoading ? "Loading..." : "Add"}
          </Button>
        </DialogActions>
      </Dialog>
      <div className="AddMasjidContainer">
        {(admin?.role === "superadmin" || admin?.role === "admin") && (
          <Button
            className="link"
            onClick={handleAddMasjidModal}
            variant="outlined"
            style={{ marginBottom: "10px", marginTop: "15px", color: "Green" }}
          >
            Add New Masjid <FcPlus className="PlusIcon" />
          </Button>
        )}
      </div>

      <div className="masjid-page-table">
        <DataGrid
          loading={loading}
          rows={
            admin?.role === "superadmin" || admin?.role === "admin"
              ? masjids
              : []
          }
          columns={MasjidColumns}
          pageSize={20}
          getRowId={(row) => row._id}
          rowsPerPageOptions={[5]}
          disableSelectionOnClick
          components={{ Toolbar: GridToolbar }}
          componentsProps={{
            toolbar: {
              showQuickFilter: true,
              quickFilterProps: { debounceMs: 500 },
            },
          }}
        />
      </div>
    </div>
  );
};
export default MasjidTable;
